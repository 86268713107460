import React, { useState } from "react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  NavigationBar,
  MobileNavigationBar,
  DesktopNavigationWrapper,
  LogoMobile,
  BackArrowWrapper,
  Title,
  SectionWrapper,
  Description,
  HorizontalDivider,
  BackgroundWrapper,
  BlueRectangle,
  BackToTopArrowWrapper,
  FooterWrapper,
  ContactInformation,
  ContactTitle,
  ContactDescription,
  TransitionWrapper,
  TransitionRandomBlock,
} from "./style";
import Navigation from "../../common/Navigation";
import { PageChange } from "../../common/Scroll";
import { LocationProp, PageThemeType } from "../../../utils/types";
import ArrowLeft from "-!svg-react-loader!../../../assets/images/arrowLeft.svg";
import ArrowUp from "-!svg-react-loader!../../../assets/images/arrowUp.svg";
import { PageTitle } from "../../common/PageTitle";

const goBackPath = "/career";
const navigationThemes = ["black"] as PageThemeType[];

const transitionRandomBlocks = Array.from(Array(6).keys()).map(c =>
  Array.from(Array(4).keys()).map(r => (
    <TransitionRandomBlock
      key={c + r}
      delay={Math.floor(Math.random() * (800 - 64 + 1)) + 64}
    />
  ))
);

const Offers: React.FC<LocationProp> = ({ location }) => {
  const [, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();

  const { t } = useTranslation("common");
  const i18nContact = useTranslation("contact");

  const scrollToTop = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goBack = () => {
    setPageChange("next");
    setTimeout(() => {
      navigate(goBackPath);
    }, 800);
  };

  return (
    <>
      <PageTitle subtitle={t(`RODO_page_title`)} />
      {/* <NavigationBar>
        <LogoMobile /> */}
        {/* <MobileNavigationBar /> */}
        <Navigation
          setModalState={setModalState}
          setPageChange={setPageChange}
          navigationThemes={navigationThemes}
          location={location}
          theme="light"
        />
      {/* </NavigationBar> */}
      <DesktopNavigationWrapper>
        <Navigation
          setModalState={setModalState}
          setPageChange={setPageChange}
          navigationThemes={navigationThemes}
          location={location}
          theme="light"
        />
      </DesktopNavigationWrapper>
      <Wrapper>
        <BackArrowWrapper onClick={goBack}>
          <ArrowLeft />
        </BackArrowWrapper>
        <SectionWrapper>
          <Title>{t(`RODO_title`)}</Title>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{ __html: t(`RODO_description`) }}
          />
        </SectionWrapper>

        <div>
          <HorizontalDivider />
          <FooterWrapper>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`oke_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`oke_location`)}
              </ContactDescription>
            </ContactInformation>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`elblag_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`elblag_location`)}
              </ContactDescription>
            </ContactInformation>
          </FooterWrapper>
        </div>

        <BackgroundWrapper>
          <BlueRectangle row="1" column="4" />
          <BlueRectangle row="2" column="2 / span 2" />
          <BlueRectangle row="3" column="1" />
          <BlueRectangle row="3" column="4" />
          <BlueRectangle row="4" column="2" />
          <BlueRectangle row="4" column="4" />
          <BlueRectangle row="5" column="3" />
          <BlueRectangle row="6" column="4" />
        </BackgroundWrapper>

        <BackToTopArrowWrapper>
          <ArrowUp onClick={scrollToTop} />
        </BackToTopArrowWrapper>

        <TransitionWrapper animation={pageChange}>
          {transitionRandomBlocks}
        </TransitionWrapper>
      </Wrapper>
    </>
  );
};

export default Offers;
